import { useCallback, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useExecuteEmailSubscribe } from 'cb-wallet-data/hooks/Gamification/useEmailSubscribe';
import { LocalStorageStoreKey } from 'cb-wallet-store/models/LocalStorageStoreKey';
import { Store } from 'cb-wallet-store/Store';
import Image from 'next/image';
import { MiamiIllustrationWrapper } from 'wallet-cds-web/components/MiamiIllustrationWrapper/MiamiIllustrationWrapper';
import { IconButton } from '@cbhq/cds-web/buttons';
import { TextInput } from '@cbhq/cds-web/controls';
import { SpotRectangle } from '@cbhq/cds-web/illustrations';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { Modal } from '@cbhq/cds-web/overlays';
import { Pressable } from '@cbhq/cds-web/system';
import { TextBody, TextHeadline, TextTitle2 } from '@cbhq/cds-web/typography';
import { OutlineButton } from ':dapp/components/OutlineButton/OutlineButton';
import { useIsMobile } from ':dapp/hooks/useIsMobile';
export const StoreKeys_emailSubscribeKey = new LocalStorageStoreKey('emailSubscribeKey');
const messages = defineMessages({
  ocsStep1Title: {
    defaultMessage: 'Welcome to Onchain Summer!',
    description: 'Subscribe modal title 1'
  },
  ocsStep1Body: {
    defaultMessage: `Onchain Summer is a celebration of onchain art, music, culture, gaming, and more. Earn points from minting your favorite drops, redeem prizes, and buy merch onchain. Join us in creating a better and more creative internet.`,
    description: 'Subscribe modal body 1'
  },
  ocsStep2Title: {
    defaultMessage: 'Get onchain and collect points',
    description: 'Subscribe modal title 2'
  },
  ocsStep2Body: {
    defaultMessage: `Collect points all summer by completing experiences on Base, bringing friends onchain, and creating on Base. Come back daily for new experiences and challenges.`,
    description: 'Subscribe modal body 2'
  },
  ocsStep3Title: {
    defaultMessage: 'Redeem points for prizes',
    description: 'Subscribe modal title 3'
  },
  ocsStep3Body: {
    defaultMessage: `Points can be redeemed throughout the summer for onchain benefits, merch, exclusive experiences, and more. Unlock more prizes as you level up.`,
    description: 'Subscribe modal body 3'
  },
  ocsStep4Title: {
    defaultMessage: 'Never miss a drop',
    description: 'Subscribe modal title 4'
  },
  ocsStep4Body: {
    defaultMessage: `Get notified about Onchain Summer's latest drops and experiences.`,
    description: 'Subscribe modal body 4'
  },
  modalCtaNext: {
    defaultMessage: `Next`,
    description: 'Subscribe modal CTA, next'
  },
  modalCtaGetStarted: {
    defaultMessage: `Get started`,
    description: 'Subscribe modal CTA, Get Started'
  },
  modalCtaSubscribe: {
    defaultMessage: `Subscribe`,
    description: 'Subscribe modal CTA'
  },
  emailInputLabel: {
    defaultMessage: `Email`,
    description: 'Email input label'
  },
  emailInputPlaceholder: {
    defaultMessage: `Email address`,
    description: 'Email input placeholder'
  },
  modalCtaSkip: {
    defaultMessage: `Skip`,
    description: 'Subscribe modal CTA Skip'
  },
  modalCtaCompleted: {
    defaultMessage: `Got it`,
    description: 'Subscribe completed modal CTA'
  },
  modalBodyCompleted: {
    defaultMessage: `Hooray! You signed up for Onchain Summer’s drop notifications. See you in your inbox.`,
    description: 'Subscribe completed modal body'
  }
});
const modalClass = "mspez5g";
const nextBtnClass = "n1l1cnfb";
const outlineBtnClass = "o1v0nziv";
type NuxWelcomeModalProps = {
  handleClose: () => void;
};
export function NuxWelcomeModal({
  handleClose
}: NuxWelcomeModalProps) {
  const {
    formatMessage
  } = useIntl();
  const isMobile = useIsMobile();
  const [errorText, setErrorText] = useState<string | undefined>(undefined);
  const [email, setEmail] = useState<string>('');
  const [step, setStep] = useState<1 | 2 | 3 | 4>(1);
  const {
    mutateAsync: executeSubscribe,
    isLoading
  } = useExecuteEmailSubscribe();
  const [completedMode, setCompletedMode] = useState(Store.get<boolean>(StoreKeys_emailSubscribeKey) || false);
  const resetState = useCallback(() => {
    setStep(1);
    setErrorText(undefined);
    setEmail('');
    setCompletedMode(Store.get<boolean>(StoreKeys_emailSubscribeKey) || false);
  }, []);
  const handleOnClose = useCallback(() => {
    handleClose();
    resetState();
  }, [handleClose, resetState]);
  const handleCTAClick = useCallback(async () => {
    if (step === 1) {
      setStep(2);
    } else if (step === 2) {
      setStep(3);
    } else if (step === 3) {
      // if select "subscribe" on step 3. "Get started" triggers handleOnClose
      setStep(4);
    } else {
      // Step 4 is either "skip" if not completed, or "got it" if completed. both trigger handleOnClose
      // fail safe
      handleOnClose();
    }
  }, [handleOnClose, step]);
  const handleSubscribeClick = useCallback(async () => {
    if (errorText) return null;
    await executeSubscribe({
      emailAddress: email
    });
    setCompletedMode(true);
    Store.set<boolean>(StoreKeys_emailSubscribeKey, true);
  }, [email, errorText, executeSubscribe]);
  const handleEmailInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setEmail(value);
    if (
    // email validation taken from `checkout` page
    !value || !/^\S+@\S+\.\S+$/.test(value.trim())) {
      setErrorText('Error: Invalid email format');
    } else {
      setErrorText(undefined);
    }
  }, []);
  return <Modal visible onRequestClose={handleOnClose} className={`${modalClass}`} testID="nux-welcome-modal" zIndex={99}>
      <img src="https://go.wallet.coinbase.com/static/OCS%20Wordmark%203.png" alt="ocs banner" width="auto" height="100%" style={{
      borderRadius: 24,
      position: 'absolute',
      zIndex: 1
    }} />
      <HStack spacingVertical={2} spacingStart={isMobile ? 3 : 4} spacingEnd={2} justifyContent="flex-end" width="100%" height={isMobile ? '50px' : '72px'} zIndex={9}>
        <IconButton testID="nux-welcome-modal-close" name="close" compact transparent onPress={handleOnClose} />
      </HStack>

      <Box width="100%" spacingTop={0} spacingHorizontal={isMobile ? 2 : 4} spacingBottom={isMobile ? 2 : 4} testID="nux-welcome-modal-content" justifyContent="center" overflow="auto" zIndex={9}>
        <VStack gap={4} spacingHorizontal={isMobile ? 0 : 3} justifyContent="center" alignItems="center" width="100%">
          {step === 1 && <>
              <video loop muted autoPlay preload="auto" playsInline draggable={false} width="90%" src="https://go.wallet.coinbase.com/static/NFT_FULL_OCS_PANO%20%281%29.webm" style={{
            maxWidth: '460px',
            maxHeight: '180px'
          }} />
              <TextTitle2 as="span" align="center">
                {formatMessage(messages.ocsStep1Title)}
              </TextTitle2>
              <TextBody as="span" align="center">
                {formatMessage(messages.ocsStep1Body)}
              </TextBody>
            </>}
          {step === 2 && <>
              <HStack justifyContent="space-between" gap={2} alignItems="center">
                <Box maxWidth="131px">
                  <Image src="https://go.wallet.coinbase.com/static/Frame%201547769427.png" alt="Frame BASE card" width={isMobile ? '79' : '131'} height={isMobile ? '105' : '174'} />
                </Box>
                <Box maxWidth="155px">
                  <Image src="https://go.wallet.coinbase.com/static/Frame%201547769428.png" alt="Frame Adidas card" width={isMobile ? '94' : '155'} height={isMobile ? '125' : '205'} />
                </Box>
                <Box maxWidth="131px">
                  <Image src="https://go.wallet.coinbase.com/static/Frame%201547769429.png" alt="Frame Moshicam card" width={isMobile ? '79' : '131'} height={isMobile ? '105' : '174'} />
                </Box>
              </HStack>
              <TextTitle2 as="span" align="center">
                {formatMessage(messages.ocsStep2Title)}
              </TextTitle2>
              <TextBody as="span" align="center">
                {formatMessage(messages.ocsStep2Body)}
              </TextBody>
            </>}
          {step === 3 && <>
              <HStack justifyContent="space-between" gap={2} alignItems="center">
                <Box maxWidth="460">
                  <Image src="https://go.wallet.coinbase.com/static/prizese%20NUX.png" alt="Prizes images" width="460" height="205" />
                </Box>
              </HStack>
              <TextTitle2 as="span" align="center">
                {formatMessage(messages.ocsStep3Title)}
              </TextTitle2>
              <TextBody as="span" align="center">
                {formatMessage(messages.ocsStep3Body)}
              </TextBody>
            </>}
          {step === 4 && <>
              <HStack justifyContent="center">
                <MiamiIllustrationWrapper illustration="PrimeDeFiRectangleMiami" isMiamiIllustration isMiamiViceIllustration>
                  <SpotRectangle name="protectedNotes" />
                </MiamiIllustrationWrapper>
              </HStack>
              <TextTitle2 as="span" align="center">
                {formatMessage(messages.ocsStep4Title)}
              </TextTitle2>
              {!completedMode ? <>
                  <TextBody as="span" align="center">
                    {formatMessage(messages.ocsStep4Body)}
                  </TextBody>
                  <TextInput label={formatMessage(messages.emailInputLabel)} helperText={errorText} variant={errorText ? 'negative' : undefined} placeholder={formatMessage(messages.emailInputPlaceholder)} onChange={handleEmailInputChange} value={email} testID="nux-subscribe-email-input" />
                </> : <TextBody as="span" align="center">
                  {formatMessage(messages.modalBodyCompleted)}
                </TextBody>}
            </>}
          <HStack gap={4}>
            {step === 3 && !completedMode && <OutlineButton buttonText={formatMessage(messages.modalCtaSubscribe)} onClick={handleCTAClick} classOverride={outlineBtnClass} />}
            {step < 4 && <Pressable background="foreground" className={nextBtnClass} testID="nux-subscribe-cta-button" onPress={step === 3 ? handleOnClose : handleCTAClick}>
                <TextHeadline as="span" color="primary">
                  {step === 3 ? formatMessage(messages.modalCtaGetStarted) : formatMessage(messages.modalCtaNext)}
                </TextHeadline>
              </Pressable>}
            {step === 4 && <>
                {!completedMode && <OutlineButton buttonText={formatMessage(messages.modalCtaSkip)} onClick={handleOnClose} classOverride={outlineBtnClass} />}
                <Pressable background="foreground" className={nextBtnClass} testID="nux-subscribe-cta-button" onPress={!completedMode ? handleSubscribeClick : handleOnClose} disabled={isLoading}>
                  <TextHeadline as="span" color="primary">
                    {completedMode ? formatMessage(messages.modalCtaCompleted) : formatMessage(messages.modalCtaSubscribe)}
                  </TextHeadline>
                </Pressable>
              </>}
          </HStack>
        </VStack>
      </Box>
    </Modal>;
}

require("./NuxWelcomeModal.linaria.module.css!=!../../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./NuxWelcomeModal.tsx");