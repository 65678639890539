import { useMutation } from '@tanstack/react-query';

import { GAME_ID, GAMIFICATION_URL } from './config';

type Params = {
  walletAddress: string;
  referralCode?: string;
};

export function useMutateOCSOptIn() {
  const { mutate, isLoading, error, data } = useMutation({
    mutationFn: async function mutateOptIn({ walletAddress, referralCode = '' }: Params) {
      const response = await fetch(`${GAMIFICATION_URL}/profile/opt-in`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          gameId: GAME_ID,
          userAddress: walletAddress,
          referralId: referralCode,
        }),
      });
      if (!response.ok) {
        throw new Error('Failed to opt in to OCS');
      }
      return response.json();
    },
  });

  return {
    mutate,
    isLoading,
    error,
    data,
  };
}
