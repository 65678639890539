import { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useRouter } from 'next/router';
import { Button } from 'wallet-cds-web/components/Button';
import { HeroSquare } from '@cbhq/cds-web/illustrations';
import { VStack } from '@cbhq/cds-web/layout';
import { TextTitle2 } from '@cbhq/cds-web/typography';

import { RoutesEnum } from ':dapp/utils/RoutesEnum';

const messages = defineMessages({
  buttonCopy: {
    description: 'Go home button',
    defaultMessage: 'Go Home',
  },
  somethingWentWrong: {
    description: 'Error page copy',
    defaultMessage: 'Page not found',
  },
});

export function NotFound() {
  const { formatMessage } = useIntl();
  const router = useRouter();
  const handleBackBtn = useCallback(async () => router.push(RoutesEnum.HOME), [router]);

  return (
    <VStack
      height="100vh"
      justifyContent="center"
      alignItems="center"
      gap={3}
      testID="not-found-page"
    >
      <HeroSquare name="errorWeb404" />
      <TextTitle2 as="h2" spacingTop={5} spacingBottom={2}>
        {formatMessage(messages.somethingWentWrong)}
      </TextTitle2>
      <Button onPress={handleBackBtn}>{formatMessage(messages.buttonCopy)}</Button>
    </VStack>
  );
}
