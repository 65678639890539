import { useEffect, useState } from 'react';
import { getBalance } from '@wagmi/core';
import {
  ETHEREUM_CHAIN_ID,
  ETHEREUM_NETWORK_ID,
} from 'cb-wallet-data/chains/AccountBased/Ethereum/constants';
import { useCurrencyFormatter } from 'cb-wallet-data/CurrencyFormatter/hooks/useCurrencyFormatter';
import { CurrencyCode } from 'cb-wallet-data/models/CurrencyCode';
import { getConfig } from 'cb-wallet-data/scw/libs/wagmi/config';
import { useExchangeRatesMap } from 'cb-wallet-data/stores/ExchangeRates/hooks/useExchangeRates';
import { getExchangeRateKey } from 'cb-wallet-data/stores/ExchangeRates/state';
import Decimal from 'decimal.js';

export function useBaseBalance(accountAddress: string) {
  const [baseBalanceFiat, setBaseBalanceFiat] = useState<string>('');
  const [baseBalanceCrypto, setBaseBalanceCrypto] = useState<string>('');
  const exchangeRatesMap = useExchangeRatesMap();
  const { fiatValueString, formatToCrypto } = useCurrencyFormatter();

  useEffect(
    function fetchBaseBalanceOnChain() {
      const fetchBaseBalance = async () => {
        const baseBalanceOnChain = await getBalance(getConfig(), {
          address: accountAddress as `0x${string}`,
          chainId: 8453,
        });

        // Convert the balance to the base unit of the currency
        const decimalBalance = new Decimal(baseBalanceOnChain.value.toString()).dividedBy(
          new Decimal(10).pow(18),
        );

        const result = decimalBalance.mul(
          exchangeRatesMap[
            getExchangeRateKey({
              chainId: ETHEREUM_CHAIN_ID,
              networkId: ETHEREUM_NETWORK_ID,
              contractAddress: undefined,
              currencyCode: new CurrencyCode('ETH'),
            })
          ],
        );

        const cryptoResult = formatToCrypto({
          currencyCode: CurrencyCode.ETH,
          ...baseBalanceOnChain,
          decimals: BigInt(baseBalanceOnChain.decimals),
        });

        setBaseBalanceFiat(fiatValueString(result));
        if (cryptoResult) {
          setBaseBalanceCrypto(cryptoResult);
        }
      };

      fetchBaseBalance();
    },
    [accountAddress, exchangeRatesMap, fiatValueString, formatToCrypto],
  );

  return { baseBalanceFiat, baseBalanceCrypto };
}
