import { useMutation } from '@tanstack/react-query';

import { UNAUTH_CB_API_BASE } from './config';

export type ExecuteEmailSubscribe = {
  emailAddress: string;
  product_id?: string;
  country_code?: string;
  locale?: string;
  currency?: string;
};

const EMAIL_SUBSCRIBE_ROUTE = 'v3/coinbase.email_only_users.SubscriptionService/Subscribe';

async function executeEmailSubscribe(args: ExecuteEmailSubscribe) {
  const response = await fetch(`${UNAUTH_CB_API_BASE}/${EMAIL_SUBSCRIBE_ROUTE}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      email: args.emailAddress,
      // eslint-disable-next-line camelcase
      product_id: args.product_id || 'wallet_onchain_summer_Q2_24',
      // eslint-disable-next-line camelcase
      country_code: args.country_code || 'US',
      locale: args.locale || 'en_US',
      currency: args.currency || 'USD',
    }),
  });

  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(`spin failed with error ${errorText}`);
  }
  return response.json();
}

export function useExecuteEmailSubscribe() {
  const { mutateAsync, isLoading, error, data } = useMutation({
    mutationFn: async (params: ExecuteEmailSubscribe) => executeEmailSubscribe(params),
  });

  return { mutateAsync, isLoading, error, data };
}
