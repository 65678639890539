import { useCallback, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Button } from 'wallet-cds-web/components/Button';
import { VStack } from '@cbhq/cds-web/layout';
import { TextHeadline } from '@cbhq/cds-web/typography';

import { HomebaseModal } from ':dapp/components/HomebaseModal/HomebaseModal';
import { AccountSelector } from ':dapp/pages/nft/mint/[contractAddress]/components/AccountSelector';

const messages = defineMessages({
  primaryCTAText: {
    defaultMessage: 'Confirm',
    description: 'CTA for selecting a wallet',
  },
  title: {
    defaultMessage: 'Select a wallet',
    description: 'Title for selecting a wallet',
  },
  description: {
    defaultMessage:
      'Please select the wallet you want to use for Onchain Summer. This address will be shown on your Summer Pass. Points, badges, and referrals will be attributed to this address.',
    description: 'Description for selecting a wallet',
  },
});

type OCSWalletSelectorModalProps = {
  handleClose: () => void;
  setAddress: (accountToUse: string) => void;
};

export function OCSWalletSelectorModal({ handleClose, setAddress }: OCSWalletSelectorModalProps) {
  const { formatMessage } = useIntl();
  // "locally" store this in this component (as opposed to depending on `account`) because `account` will sequentially refresh for every wallet imported when connecting >1 wallet -> causing weird jumping. see WALL-39201
  const [currentlySelectedAddress, setCurrentlySelectedAddress] = useState<string | undefined>(
    undefined,
  );

  /* istanbul ignore next */
  const handleOnSetAccountAddress = useCallback(
    (address: string) => {
      setCurrentlySelectedAddress(address);
      setAddress(address);
    },
    [setAddress],
  );

  return (
    <HomebaseModal
      title={formatMessage(messages.title)}
      visible
      onClose={handleClose}
      testID="multi-wallet-selector"
    >
      <VStack gap={4}>
        <TextHeadline as="p" color="foregroundMuted">
          {formatMessage(messages.description)}
        </TextHeadline>
        <AccountSelector
          selectedAccountAddress={currentlySelectedAddress}
          setSelectedAccountAddress={handleOnSetAccountAddress}
        />
        <Button onClick={handleClose}>{formatMessage(messages.primaryCTAText)}</Button>
      </VStack>
    </HomebaseModal>
  );
}
