import { GradientButton } from 'wallet-cds-web/components/GradientButton';
import { IconName } from '@cbhq/cds-common';
type OutlineButtonProps = {
  onClick?: () => void;
  variant?: 'primary' | 'secondary';
  endIcon?: IconName;
  buttonText: string;
  compact?: boolean;
  classOverride?: string;
  textClass?: string;
};
const bgClass = "bab5g65";
export function OutlineButton({
  onClick,
  variant = 'secondary',
  endIcon,
  buttonText,
  compact = false,
  classOverride = '',
  textClass = ''
}: OutlineButtonProps) {
  return <GradientButton compact={compact} variant={variant} endIcon={endIcon} testID="gradient-outline-btn" onPress={onClick} gradientBackgroundClass={`${bgClass} ${classOverride}`}>
      <span className={`${textClass}`}>{buttonText}</span>
    </GradientButton>;
}

require("./OutlineButton.linaria.module.css!=!../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./OutlineButton.tsx");