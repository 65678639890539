/* istanbul ignore file */
import { useCallback, useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import {
  AccountWithBalance,
  useAccountsWithBalances,
} from 'cb-wallet-data/stores/Accounts/hooks/useAccountsWithBalances';
import { FormattedAddressWithDomain } from 'wallet-cds-web/components/FormattedAddressWithDomain';
import { ProfileAvatar } from 'wallet-cds-web/components/ProfileAvatar';
import { SelectOption } from '@cbhq/cds-web/controls';
import { Dropdown } from '@cbhq/cds-web/dropdown';
import { Icon } from '@cbhq/cds-web/icons';
import { HStack, VStack } from '@cbhq/cds-web/layout';
import { PopoverContentPositionConfig } from '@cbhq/cds-web/overlays';
import { TextBody } from '@cbhq/cds-web/typography';

import { useBaseBalance } from ':dapp/hooks/useBaseBalance';

const messages = defineMessages({
  selectWallet: {
    defaultMessage: 'Select wallet',
    description: 'Label for the wallet selection dropdown',
  },
});

type AccountSelectorProps = {
  selectedAccountAddress?: string;
  setSelectedAccountAddress: (address: `0x${string}`) => void;
};

export function AccountSelector({
  selectedAccountAddress,
  setSelectedAccountAddress,
}: AccountSelectorProps) {
  const accountsWithBalances = useAccountsWithBalances();
  const accounts = useMemo(
    () => accountsWithBalances.filter((f) => f.primaryAddressChain === 'ETH'),
    [accountsWithBalances],
  );
  const selectedAccount = useMemo(
    () =>
      accountsWithBalances.find(
        (acc) => selectedAccountAddress && acc.primaryAddressForDapp === selectedAccountAddress,
      ),
    [selectedAccountAddress, accountsWithBalances],
  );
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = useCallback(() => setDropdownOpen((prev) => !prev), []);

  const content = useMemo(() => {
    return (
      <VStack gap={1} overflow="auto" width="100%">
        {accounts.map((accountWithBalance) => {
          return (
            <SelectOption
              key={accountWithBalance.primaryAddressForDapp}
              value={accountWithBalance.primaryAddressForDapp}
              title={<AccountSelectorRow account={accountWithBalance} />}
            />
          );
        })}
      </VStack>
    );
  }, [accounts]);

  const contentPosition = useMemo(
    () => ({
      placement: 'bottom-end' as PopoverContentPositionConfig['placement'],
    }),
    [],
  );

  // Don't show the dropdown if there's only one account, or if there are no
  // accounts because the user is not connected
  if (accountsWithBalances.length <= 1) {
    return null;
  }

  return (
    <Dropdown
      value={selectedAccountAddress}
      onChange={setSelectedAccountAddress as (address: string) => void}
      content={content}
      onOpenMenu={toggleDropdown}
      onCloseMenu={toggleDropdown}
      testID="select-wallet-dropdown"
      contentPosition={contentPosition}
    >
      <HStack gap={1} alignItems="center" justifyContent="space-between">
        <SelectedAccountRow address={selectedAccount?.primaryAddressForDapp || ''} />
        <Icon name={dropdownOpen ? 'caretUp' : 'caretDown'} size="s" color="foreground" />
      </HStack>
    </Dropdown>
  );
}

type SelectedAccountRowProps = {
  address: string;
};

function SelectedAccountRow({ address }: SelectedAccountRowProps) {
  const { formatMessage } = useIntl();

  return (
    <HStack alignItems="center" gap={2}>
      <ProfileAvatar alt={address} address={address} size="l" />
      <VStack>
        <TextBody as="p" color="foreground" testID="select-wallet-label">
          {formatMessage(messages.selectWallet)}
        </TextBody>
        <HStack gap={1} alignItems="center">
          <TextBody color="primary" as="div">
            <FormattedAddressWithDomain
              address={address}
              variant="foregroundMuted"
              textComponentName="TextBody"
              domainColor="foregroundMuted"
            />
          </TextBody>
        </HStack>
      </VStack>
    </HStack>
  );
}

type AccountSelectorRowProps = {
  account: AccountWithBalance;
};

function AccountSelectorRow({ account }: AccountSelectorRowProps) {
  const address = account.primaryAddressForDapp;
  const { baseBalanceCrypto } = useBaseBalance(address);

  return (
    <HStack gap={1} alignItems="center">
      <ProfileAvatar alt={address} address={address} size="l" />
      <VStack>
        <FormattedAddressWithDomain address={address} />
        <TextBody as="p" color="foregroundMuted">
          {baseBalanceCrypto}
        </TextBody>
      </VStack>
    </HStack>
  );
}
