import { FeatureName } from 'cb-wallet-data/FeatureManager/featureConfigs';
import {
  IsFeatureEnabledOptions,
  useIsFeatureEnabled,
} from 'cb-wallet-data/FeatureManager/hooks/useIsFeatureEnabled';
import { InferGetStaticPropsType } from 'next';

import { NotFound } from '../NotFound/NotFound';

export function withFeatureEnabled<T extends (args: unknown) => unknown>(
  Component: React.ComponentType<InferGetStaticPropsType<T>>,
  featureName: FeatureName,
  opts: IsFeatureEnabledOptions = {},
) {
  function FeatureEnabledComponent(props: Record<string, unknown>) {
    const enabled = useIsFeatureEnabled(featureName, opts);
    if (!enabled) {
      return <NotFound />;
    }
    return <Component {...props} />;
  }
  // Hoist the getLayout static if it exists
  if ('getLayout' in Component) {
    FeatureEnabledComponent.getLayout = Component.getLayout;
  }

  // Hoist the Layout static if it exists
  if ('Layout' in Component) {
    FeatureEnabledComponent.Layout = Component.Layout;
  }

  return FeatureEnabledComponent;
}
