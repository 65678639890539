import { useIsMiamiViceFeatureEnabled } from 'cb-wallet-data/hooks/useIsMiamiViceFeatureEnabled/useIsMiamiViceFeatureEnabled';
import { useMiamiIllustrationsEnabled } from 'cb-wallet-data/hooks/useMiamiIllustrationsEnabled/useMiamiIllustrationsEnabled';

import { MiamiIllustration, MiamiIllustrationWrapperProps } from './MiamiIllustration';

export function MiamiIllustrationWrapper(props: MiamiIllustrationWrapperProps) {
  const isMiamiIllustrationsEnabled = useMiamiIllustrationsEnabled();
  const isMiamiViceIllustrationEnabled = useIsMiamiViceFeatureEnabled(
    'wallet_redesign_miami_vice_theme_colors',
  );

  return (
    <MiamiIllustration
      isMiamiIllustration={isMiamiIllustrationsEnabled}
      isMiamiViceIllustration={isMiamiViceIllustrationEnabled}
      {...props}
    />
  );
}
